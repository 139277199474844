import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

document.addEventListener("DOMContentLoaded", () => {
    const sliders = [...document.querySelectorAll('.keen-slider')]
    sliders.forEach((slider) => setupSlider(slider))
});

function setupSlider(slider) {
    const sliderElement = document.getElementById(slider.id);
    if (sliderElement) {
        const dotContainer = document.getElementById(slider.id + '-dots')

        var slider = new KeenSlider(
            sliderElement,
            {
                slides: {
                    perView: 1.15,
                },
                loop: true,
            },
            [
                (slider) => {
                    slider.on('created', (current) => {
                        setDotActive(dotContainer, current.track.details.rel)
                    })
                    slider.on("slideChanged", (current) => {
                        setDotActive(dotContainer, current.track.details.rel)
                    })
                },
            ]
        );
    }
}

function setDotActive(dotContainer, index) {
    [...dotContainer.children].forEach((dot, i) => {
        dot.classList.remove('bg-orange')
        dot.classList.add('bg-red-darkest')
        if (index === i) {
            dot.classList.remove('bg-red-darkest')
            dot.classList.add('bg-orange')
        }
    })
}
